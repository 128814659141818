import { Avatar } from "@mui/material";

import { Help, Logout } from "@mui/icons-material";

function Account(props) {
  function signOut() {
    fetch(`https://poose.lennart.codes/easy-trade/api/sessions`, {
      method: "DELETE",
      headers: {
        Authorization: props.session,
      },
    }).catch(() => {});
    props.setStage("Login");
    localStorage.removeItem("session");
  }

  const user = props.user;

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div
        className="bg-white rounded flex flex-col p-5 gap-y-5"
        style={{ width: props.isMobile && "90%" }}
      >
        <div
          className="flex flex-row justify-between"
          style={{ columnGap: !props.isMobile && "176px" }}
        >
          <div>
            <h1 className="text-2xl font-semibold">{user?.name}</h1>
            <h1 className="font-medium">
              @{user?.name && user.name.replaceAll(" ", "")}
            </h1>
          </div>
          <Avatar alt={user?.name} sx={{ width: 56, height: 56 }}>
            {user?.name &&
              `${user.name.split(" ")[0][0]}${user.name.split(" ")[1][0]}`}
          </Avatar>
        </div>
        <div className="p-5 bg-gray-100 rounded flex flex-col gap-y-3">
          <div className="flex flex-row justify-between">
            <h1 className="font-medium opacity-60">Email</h1>
            <h1>{user?.email}</h1>
          </div>
        </div>
        <div className="p-5 bg-gray-100 rounded flex flex-col gap-y-3">
          <div className="flex flex-row justify-between">
            <h1 className="font-medium opacity-60">Balance</h1>
            <h1>{user?.total_money?.toFixed(2)} €</h1>
          </div>
          <div className="flex flex-row justify-between">
            <h1 className="font-medium opacity-60">IBAN</h1>
            <h1>DE75512108111245126199</h1>
          </div>
          <div className="flex flex-row justify-between">
            <h1 className="font-medium opacity-60">BIC</h1>
            <h1>ALS1234</h1>
          </div>
        </div>
        <div
          className="p-5 bg-gray-100 rounded flex items-center justify-between cursor-pointer"
          onClick={() =>
            window.open("https://www.wikihow.com/Invest-in-Stocks", "_blank")
          }
        >
          <h1 className="font-medium">Help</h1>
          <div className="text-blue">
            <Help></Help>
          </div>
        </div>
        <div
          className="p-5 bg-gray-100 rounded flex items-center justify-between cursor-pointer"
          onClick={signOut}
        >
          <h1 className="font-medium">Sign Out</h1>
          <div className="text-blue">
            <Logout></Logout>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
