import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useEffect, useState } from "react";
import Logo from "../logo.svg";

function Login(props) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);

  const [nameValid, setNameValid] = useState(false);

  const [emailValid, setEmailValid] = useState(false);

  useEffect(() => {
    setNameValid(/^[A-z]*(\s[A-z]*)+$/.test(name));
  }, [name]);

  useEffect(() => {
    setEmailValid(/^(.+)@(.+)$/.test(email));
  }, [email]);

  const [password, setPassword] = useState(null);

  const [error, setError] = useState(null);

  function doRegister() {
    fetch(`https://poose.lennart.codes/easy-trade/api/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, name, password }),
    })
      .then((r) => {
        if (r.status === 200) {
          r.text().then((t) => {
            props.setSession(t);
            localStorage.setItem("session", t);
            props.setStage("App");
          });
        } else {
          r.json().then((j) => {
            setError(j.details);
          });
        }
      })
      .catch((e) => {
        setError("Please connect to the internet");
      });
  }

  return (
    <div className="w-screen h-[calc(100dvh)] flex flex-col justify-center items-center bg-gray-200">
      <div className="absolute top-5 left-5">
        <img src={Logo} width={72}></img>
      </div>
      <div className="w-80 bg-white rounded flex flex-col p-8 gap-y-5">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-medium">Register</h1>
          <h1
            className="text-md font-medium p-1.5 pl-3 pr-3 bg-blue rounded bg-opacity-20 cursor-pointer text-blue"
            onClick={() => props.setStage("Login")}
          >
            Login ?
          </h1>
        </div>
        <TextField
          id="outlined-basic"
          label="Full Name"
          variant="filled"
          size="small"
          onChange={(event) => {
            setName(event.target.value);
          }}
          InputProps={{ disableUnderline: true }}
        />
        <TextField
          id="outlined-basic"
          label="Email"
          variant="filled"
          size="small"
          type="email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          InputProps={{ disableUnderline: true }}
          required={true}
        />
        <TextField
          id="outlined-basic"
          label="Password"
          variant="filled"
          size="small"
          type="password"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          InputProps={{ disableUnderline: true }}
        />
        <Button
          variant="contained"
          sx={{ marginTop: "10px" }}
          disabled={!nameValid || !emailValid || !password}
          onClick={doRegister}
        >
          Get Started
        </Button>
        {error && <h2 className="text-red bottom-1 font-medium">{error}</h2>}
      </div>
    </div>
  );
}

export default Login;
