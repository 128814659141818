import Chart from "./Chart";

import { Button } from "@mui/material";

function Stock(props) {
  const isMobile = props.isMobile;

  const aproxPrice = props.price && props.price.toFixed(2);

  return (
    <div className="bg-white rounded flex flex-col p-5">
      <div
        className="h-full flex justify-between items-center gap-y-5"
        style={{ flexDirection: isMobile ? "column" : "row" }}
      >
        <div className="h-full w-[80%] flex flex-col justify-between gap-y-5">
          <div>
            <h1 className="text-xl">{props.name}</h1>
            <h2 className="text-lg font-bold">{aproxPrice} €</h2>
            {props.quantity && (
              <h2 className="text-gray-500 text-sm font-bold">
                {props.quantity} Total ·{" "}
                {(props.quantity * aproxPrice).toFixed(2)} €
              </h2>
            )}
          </div>
          <div
            className="flex gap-2 w-full justify-between"
            style={{ flexDirection: isMobile ? "row" : "column" }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#008FFB",
                width: isMobile ? "90px" : "144px",
              }}
              disabled={props.price > props.user.money}
              onClick={() =>
                props.buyStock({
                  id: props.id,
                  price: props.price,
                  name: props.name,
                })
              }
            >
              Buy
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ff4757",
                width: isMobile ? "90px" : "144px",
              }}
              disabled={!props.quantity}
              onClick={() =>
                props.sellStock({
                  id: props.id,
                  price: props.price,
                  name: props.name,
                })
              }
            >
              Sell
            </Button>
          </div>
        </div>
        <div className="translate-y-3">
          <Chart
            name={props.name}
            height={isMobile ? "200" : "290"}
            width={isMobile ? "280" : "370"}
            data={props.data}
          ></Chart>
        </div>
      </div>
    </div>
  );
}

export default Stock;
