import { Button, Modal, TextField, Box, Typography } from "@mui/material";
import Stock from "./Stock";

import { DoDisturb } from "@mui/icons-material";

import { useEffect, useState } from "react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function StocksHandler(props) {
  const stocks = props.stocks;
  const selectedStocks = props.selectedStocks;
  const isMobile = props.isMobile;

  const [buy, setBuy] = useState(null);
  const [buyAmount, setBuyAmount] = useState(1);

  const [sell, setSell] = useState(null);
  const [sellAmount, setSellAmount] = useState(1);

  const toSellQuantity =
    sell &&
    props?.user?.stocks &&
    props.user.stocks.find((s) => s.id === sell.id)?.quantity;

  return selectedStocks?.length || stocks?.length ? (
    <div className="relative h-full">
      <Modal
        open={buy}
        onClose={() => setBuy(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Buy {buy?.name}
          </Typography>
          <div
            id="modal-modal-description"
            className="flex justify-between gap-x-3 pt-4"
          >
            <TextField
              id="outlined-basic"
              label="Quantity"
              variant="filled"
              size="small"
              type="number"
              onChange={(event) => {
                setBuyAmount(event.target.value);
              }}
              InputProps={{ disableUnderline: true }}
            />
            <Button
              variant="contained"
              style={{
                backgroundColor: "#008FFB",
                width: isMobile ? "90px" : "144px",
              }}
              disabled={
                !parseInt(buyAmount) ||
                !buy?.price ||
                !props?.user?.money ||
                buy.price * parseInt(buyAmount) > props.user.money
              }
              onClick={() => {
                props.buyStock(buy?.id, parseInt(buyAmount));
                setBuy(null);
              }}
            >
              Buy
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={sell}
        onClose={() => setSell(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Sell {sell?.name}
          </Typography>
          <div
            id="modal-modal-description"
            className="flex justify-between gap-x-3 pt-4"
          >
            <TextField
              id="outlined-basic"
              label="Quantity"
              variant="filled"
              size="small"
              type="number"
              onChange={(event) => {
                setSellAmount(event.target.value);
              }}
              InputProps={{ disableUnderline: true }}
            />
            <Button
              variant="contained"
              style={{
                backgroundColor: "#008FFB",
                width: isMobile ? "90px" : "144px",
              }}
              disabled={
                !sellAmount ||
                !sell?.id ||
                !toSellQuantity ||
                parseInt(sellAmount) > toSellQuantity
              }
              onClick={() => {
                props.sellStock(sell?.id, parseInt(sellAmount));
                setSell(null);
              }}
            >
              Sell
            </Button>
          </div>
        </Box>
      </Modal>

      <div
        className={
          isMobile
            ? "flex flex-col h-full overflow-y-scroll absolute gap-y-3 w-[calc(100%+12px)] "
            : "grid grid-cols-[repeat(auto-fill,_36rem)] grid-rows-[repeat(auto-fill,20rem)] justify-center xl:justify-between 2xl:justify-between gap-x-2 gap-y-4 w-[calc(100%+12px)] h-full overflow-y-scroll absolute"
        }
      >
        {selectedStocks.length
          ? selectedStocks.map((s) => (
              <Stock
                id={s.id}
                name={s.name}
                price={s.current_value}
                data={s.chart}
                quantity={s.quantity}
                isMobile={isMobile}
                user={props.user}
                buyStock={setBuy}
                sellStock={setSell}
              ></Stock>
            ))
          : stocks.map((s) => (
              <Stock
                id={s.id}
                name={s.name}
                price={s.current_value}
                data={s.chart}
                quantity={s.quantity}
                isMobile={isMobile}
                user={props.user}
                buyStock={setBuy}
                sellStock={setSell}
              ></Stock>
            ))}
      </div>
    </div>
  ) : (
    <div className="w-full h-[70%] flex flex-col items-center justify-center gap-y-2 opacity-50">
      <h1 className="text-2xl font-medium">no stocks yet</h1>
      <DoDisturb fontSize="large" />
    </div>
  );
}

export default StocksHandler;
