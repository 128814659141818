import { AccountBalance } from "@mui/icons-material";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { useEffect, useState } from "react";
import StocksHandler from "./StocksHandler";

function Stocks(props) {
  const [selectedStocks, setSelectedStocks] = useState([]);

  const isMobile = props.isMobile;

  const user = props.user;

  const stocks = props.stocks;

  return (
    <div className="w-full h-full pl-8 pr-8 md:pl-12 md:pr-12 lg:pl-24 lg:pr-24 2xl:pr-48 2xl:pl-48 pt-10 flex flex-col gap-10">
      <div className="flex flex-col w-full h-full gap-y-7">
        <div
          className="flex justify-between items-center gap-y-2"
          style={{ flexDirection: isMobile ? "column" : "row" }}
        >
          <div
            className="flex items-center"
            style={
              isMobile
                ? { justifyContent: "space-between", width: "100%" }
                : { columnGap: "20px" }
            }
          >
            <h2 className="text-2xl font-medium"> All Stocks</h2>
            <div className="flex gap-x-2 items-center">
              <div className="text-gray-500">
                <AccountBalance></AccountBalance>
              </div>
              <h2 className="text-lg font-medium  text-gray-500">
                {user?.money} €
              </h2>
            </div>
          </div>

          <Autocomplete
            value={selectedStocks}
            onChange={(event, newValue) => {
              setSelectedStocks(newValue);
            }}
            multiple
            size="small"
            options={stocks}
            sx={isMobile ? { width: "100%" } : { minWidth: "18rem" }}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Filter" variant="standard" />
            )}
          />
        </div>
        <StocksHandler
          stocks={
            user && stocks
              ? stocks.map((e, i) => {
                  let temp = user.stocks.find(
                    (uStock) => uStock.stock_id === e.id
                  );
                  if (temp?.quantity) {
                    e.quantity = temp.quantity;
                  }
                  return e;
                })
              : stocks
          }
          selectedStocks={selectedStocks}
          isMobile={isMobile}
          user={user}
          buyStock={props.buyStock}
          sellStock={props.sellStock}
        ></StocksHandler>
      </div>
    </div>
  );
}

export default Stocks;
