import Chart from "react-apexcharts";

function ApexChart(props) {
  return (
    <Chart
      options={{
        chart: {
          id: "area",
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          decimalsInFloat: 2,
        },
        dataLabels: {
          enabled: false,
        },
      }}
      series={[
        {
          name: props.name,
          data: props.data,
        },
      ]}
      type="line"
      width={props.width}
      height={props.height}
    />
  );
}

export default ApexChart;
