import { AccountBalance } from "@mui/icons-material";

import { Avatar } from "@mui/material";

import StocksHandler from "./StocksHandler";

function Portfolio(props) {
  const user = props.user;

  return (
    <div className="w-full h-full pl-8 pr-8 md:pl-12 md:pr-12 lg:pl-24 lg:pr-24 2xl:pr-48 2xl:pl-48 pt-10 flex flex-col gap-10">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-y-1">
          <h1
            className={
              "opacity-90 " + props.isMobile
                ? "text-4xl font-medium"
                : "text-6xl"
            }
          >
            {user?.total_money?.toFixed(2)} €
          </h1>
          <div className="flex gap-x-2 items-center">
            <div className="text-gray-500">
              <AccountBalance></AccountBalance>
            </div>
            <h2 className="text-lg font-medium  text-gray-500">
              {user?.money}€ left to invest
            </h2>
          </div>
        </div>

        {props.isMobile && (
          <div>
            <Avatar alt={user.name} sx={{ width: 64, height: 64 }}>
              {user?.name &&
                `${user.name.split(" ")[0][0]}${user.name.split(" ")[1][0]}`}
            </Avatar>
          </div>
        )}
      </div>
      <div className="flex flex-col w-full h-full gap-y-7">
        <h2 className="text-2xl font-medium">Portfolio</h2>
        <StocksHandler
          stocks={user.stocks}
          selectedStocks={[]}
          isMobile={props.isMobile}
          user={user}
          buyStock={props.buyStock}
          sellStock={props.sellStock}
        ></StocksHandler>
      </div>
    </div>
  );
}

export default Portfolio;
