import logo from "./logo.svg";
import "./App.css";
import Main from "./elements/Tabs";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Login from "./elements/Login";
import Register from "./elements/Register";
import { useState, useEffect } from "react";

function App() {
  const [stage, setStage] = useState(null);

  const [session, setSession] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const k = localStorage.getItem("session");
    if (k) {
      setSession(k);
      setStage("App");
    } else if (stage !== "Login" && stage !== "Register") {
      setStage("Register");
    }
  }, [stage]);

  const isMobile = width <= 768;

  return (() => {
    switch (stage) {
      case "Login":
        return <Login setStage={setStage} setSession={setSession} />;
      case "Register":
        return <Register setStage={setStage} setSession={setSession} />;
      case "App":
        return (
          <Main
            stageState={[stage, setStage]}
            isMobile={isMobile}
            session={session}
          />
        );
      default:
        return <div className="w-screen h-[calc(100dvh)] bg-gray-200"></div>;
    }
  })();
}

export default App;
