import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  InsertChart,
  DonutLarge,
  AccountCircle,
  Logout,
} from "@mui/icons-material";
import { Avatar, CircularProgress } from "@mui/material";

import Portfolio from "./Portfolio";
import Stocks from "./Stocks";
import Account from "./Account";

import Logo from "../logo.svg";
import { useEffect, useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="h-full w-full"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    iconPosition: "start",
    sx: {
      justifyContent: "flex-start",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      width: "14rem",
    },
  };
}

function Mobile(props) {
  const [value, setValue] = props.valueState;

  return (
    <div className="flex flex-row h-[calc(100dvh)] w-screen">
      <div className="flex flex-col h-full w-full">
        {props.children}
        <div className="flex flex-row items-center justify-center">
          <Tabs value={value} onChange={props.handleChange}>
            <Tab icon={<DonutLarge />} sx={{ height: "65px" }} />
            <Tab icon={<InsertChart />} sx={{ height: "65px" }} />
            <Tab icon={<AccountCircle />} sx={{ height: "65px" }} />
          </Tabs>
        </div>
      </div>
    </div>
  );
}

function Content(props) {
  const [value, setValue] = props.valueState;

  return (
    <div className="grow bg-gray-200">
      <TabPanel value={value} index={0}>
        <Portfolio
          isMobile={props.isMobile}
          user={props.user}
          buyStock={props.buyStock}
          sellStock={props.sellStock}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Stocks
          isMobile={props.isMobile}
          user={props.user}
          stocks={props.stocks}
          buyStock={props.buyStock}
          sellStock={props.sellStock}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Account
          setStage={props.setStage}
          isMobile={props.isMobile}
          user={props.user}
          session={props.session}
        />
      </TabPanel>
    </div>
  );
}

function Desktop(props) {
  const [value, setValue] = props.valueState;

  return (
    <div className="flex flex-row h-[calc(100dvh)] w-screen">
      <div className="flex h-full w-full">
        <div className="absolute top-[32px] left-[32px] flex flex-col gap-y-3">
          <Avatar alt={props.user?.name} sx={{ width: 56, height: 56 }}>
            {props?.user?.name &&
              `${props.user.name.split(" ")[0][0]}${
                props.user.name.split(" ")[1][0]
              }`}
          </Avatar>
          <div>
            <h2 className="text-lg font-medium">{props.user?.name}</h2>
            <p className="text-xs font-bold text-gray-500">
              {props.user?.total_money?.toFixed(2)} € Total
            </p>
          </div>
        </div>
        <div className="absolute bottom-[32px] left-[32px]">
          <img src={Logo} width={42} />
        </div>
        <div className="h-full flex flex-col items-start justify-center">
          <Tabs
            orientation="vertical"
            value={value}
            onChange={props.handleChange}
            TabIndicatorProps={{
              sx: {
                left: 0,
                width: "3px",
                borderTopRightRadius: "3px",
                borderBottomRightRadius: "3px",
              },
            }}
          >
            <Tab icon={<DonutLarge />} label="Portfolio" {...a11yProps(0)} />
            <Tab icon={<InsertChart />} label="Stocks" {...a11yProps(1)} />
            <Tab icon={<AccountCircle />} label="Account" {...a11yProps(2)} />
          </Tabs>
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default function Main(props) {
  const [value, setValue] = useState(0);
  const session = props.session;

  const [stage, setStage] = props.stageState;

  const [user, setUser] = useState(null);
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    const id = setInterval(() => {
      updateUser();
    }, 15000);
    updateUser();
    return () => clearInterval(id);
  }, []);

  function updateUser() {
    getUser()
      .then(async (u) => {
        if (u)
          try {
            await updateStocks(u);
            await updateUserStocks(u);
          } catch (e) {}
      })
      .catch(() => {});
  }

  async function updateUserStocks(u) {
    for (let i = 0; i < u.stocks.length; i++) {
      let uStock = u.stocks[i];

      let stock = await getStock(uStock.stock_id);
      if (!stock) return;
      stock.quantity = uStock.quantity;
      u.stocks[i] = stock;
    }
    console.log(u);
    setUser(u);
  }

  async function updateStocks(u) {
    return getStocks().then((stocks) => {
      if (!stocks) return;
      u.stocks.forEach((uStock) => {
        let stock = stocks.find((s) => s.id === uStock.stock_id);
        if (stock) {
          stock.quantity = uStock.quantity;
        }
      });
      console.log(stocks);
      setStocks(stocks);
    });
  }

  async function getUser() {
    return fetch(`https://poose.lennart.codes/easy-trade/api/user`, {
      method: "GET",
      headers: {
        Authorization: session,
      },
    })
      .then((r) => r.json())
      .catch((e) => {});
  }

  async function getStock(id) {
    return fetch(`https://poose.lennart.codes/easy-trade/api/stocks/${id}`, {
      method: "GET",
    })
      .then((r) => r.json())
      .catch((e) => {});
  }

  async function getStocks() {
    return fetch(`https://poose.lennart.codes/easy-trade/api/stocks`, {
      method: "GET",
    })
      .then((r) => r.json())
      .catch((e) => {});
  }

  function tradeStock(type, id, quantity) {
    fetch(`https://poose.lennart.codes/easy-trade/api/user/${type}`, {
      method: "POST",
      headers: {
        Authorization: session,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stock_id: id, quantity: quantity }),
    }).finally(() => {
      updateUser();
    });
  }

  function buyStock(id, quantity) {
    tradeStock("buy", id, quantity);
  }
  function sellStock(id, quantity) {
    tradeStock("sell", id, quantity);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const mainContent = (
    <Content
      setStage={setStage}
      valueState={[value, setValue]}
      isMobile={props.isMobile}
      user={user}
      stocks={stocks}
      buyStock={buyStock}
      sellStock={sellStock}
      session={session}
    ></Content>
  );

  return user ? (
    props.isMobile ? (
      <Mobile
        valueState={[value, setValue]}
        handleChange={handleChange}
        setStage={setStage}
        isMobile={props.isMobile}
      >
        {mainContent}
      </Mobile>
    ) : (
      <Desktop
        user={user}
        valueState={[value, setValue]}
        handleChange={handleChange}
        setStage={setStage}
        isMobile={props.isMobile}
      >
        {mainContent}
      </Desktop>
    )
  ) : (
    <div className="w-screen h-[calc(100dvh)] bg-gray-200 flex items-center justify-center">
      <CircularProgress />
    </div>
  );
}
